/*
 * Copyright (C) 2023 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useState } from 'react';

import { Breakpoints } from 'utils/enums';

// Components

const PurchasedFlightsCategory = styled('div')(({ theme }) => ({
  display: 'block',
  height: theme.typography.pxToRem(56),
  marginTop: theme.spacing(5.25),
  overflow: 'hidden',
  width: '100%',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    display: 'inline-block',
    width: '50%',
  },

  [theme.breakpoints.up(1440)]: {
    height: 'unset',
    overflow: 'unset',
    width: 'unset',
  },
}));

const LabelText = styled(Typography)(({ theme }) => ({
  display: 'block',
  paddingBottom: theme.typography.pxToRem(8),
  textTransform: 'uppercase',
}));

// (Performance) Prevent recreating css classes in run time
const styles = makeStyles((theme) => ({
  flapText: {
    height: theme.typography.pxToRem(19),
    lineHeight: theme.typography.pxToRem(19),
  },
  purchasedFlightsChar: {
    alignItems: 'center',
    backgroundColor: theme.palette.brandYellow100,
    borderRadius: theme.borderRadius[0],
    display: 'inline-flex',
    height: theme.typography.pxToRem(32),
    justifyContent: 'center',
    marginRight: theme.spacing(0.5),
    maxWidth: theme.typography.pxToRem(29),
    width: theme.typography.pxToRem(29),

    [theme.breakpoints.up(Breakpoints.Md)]: {
      marginRight: theme.spacing(0.6),
    },
  },
}));

interface SplitFlapFieldProps {
  value: string | undefined;
  label: string;
  numFlaps: number;
  charSet: string[];
  showCurrency?: boolean;
  transitionDurationMs?: number;
}

const CURRENCY_SYMBOL = '$';

// Using just a space character causes the text to shift upwards
const WHITESPACE_CHAR = '⠀';

// The number of steps to use when transitioning to a new value
const NUM_TICKS = 10;

/**
 * A field that displays a value using a transition inspired by a split-flap
 * display.
 *
 * @param value - The value to display
 * @param label - The label to display above the value
 * @param numFlaps - The number of flaps to use to display the value
 * @param charSet - The set of characters to use for the flaps
 * @param showCurrency - Whether to display the currency symbol before the value
 * @param transitionDurationMs - How long the transition should last, in ms. Defaults to 1500ms
 *
 * @returns
 */
const SplitFlapField: React.FC<SplitFlapFieldProps> = ({
  value,
  label,
  numFlaps,
  charSet,
  showCurrency,
  transitionDurationMs = 1500,
}) => {
  /**
   * Converts the value to an array of characters, and pads it with the space
   * character
   */
  const getValue = useCallback(
    (value: string | undefined) =>
      new Array(numFlaps).fill(' ').map((_, index) => {
        if (!value) return WHITESPACE_CHAR;
        return index + 1 > value?.length ? WHITESPACE_CHAR : value[index];
      }),
    [numFlaps]
  );

  const [currentValue, setCurrentValue] = useState<string[]>(getValue(value));

  /**
   * Cycle each character in ticker to the next character in the charSet, unless
   * it already matches the correct position in the new value
   */
  const tick = useCallback(
    (newValue: string[]) => {
      setCurrentValue((oldValue) => {
        return oldValue.map((char, i) => {
          if (char === newValue[i]) {
            return char;
          }
          if (i >= newValue.length) {
            return WHITESPACE_CHAR;
          }
          const charIndex = (charSet.indexOf(char) + 1) % charSet.length;
          return charSet[charIndex];
        });
      });
    },
    [charSet, setCurrentValue]
  );

  /**
   * Start the transition to a new value. If the new string has not been reached
   * within transitionDurationMs, set it to the new value without ticking
   * through the characters in between
   */
  const transition = useCallback(
    (newValue: string[]) => {
      const tickDuration = transitionDurationMs / NUM_TICKS;

      // Tick as many times as we can within the transition duration
      const timeouts: NodeJS.Timeout[] = [];
      for (let i = 0; i < NUM_TICKS; i++) {
        timeouts.push(setTimeout(() => tick(newValue), i * tickDuration));
      }
      /*
       * Make sure the final value is set when the transition is complete
       */
      timeouts.push(
        setTimeout(() => {
          setCurrentValue(newValue);
        }, transitionDurationMs)
      );
      return () => timeouts.forEach((timeout) => clearTimeout(timeout));
    },
    [tick, transitionDurationMs]
  );

  // Start a new transition whenever the value changes
  useEffect(() => {
    const newValue = getValue(value);
    return transition(newValue);
  }, [transition, value, getValue]);

  const style = styles();

  return (
    <PurchasedFlightsCategory>
      {label && (
        <LabelText variant="caption">
          <b>{label}</b>
        </LabelText>
      )}

      {showCurrency && (
        <div className={style.purchasedFlightsChar}>
          <Typography className={style.flapText} variant="body1">
            {CURRENCY_SYMBOL}
          </Typography>
        </div>
      )}

      {currentValue.map((char, index) => (
        <div className={style.purchasedFlightsChar} key={`${char}-${index}`}>
          <Typography className={style.flapText} variant="body1">
            {char}
          </Typography>
        </div>
      ))}
    </PurchasedFlightsCategory>
  );
};

export { SplitFlapField, SplitFlapFieldProps };
