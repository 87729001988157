/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled } from '@material-ui/core';
import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData, IImage } from 'gatsby-plugin-image';
import React from 'react';

import { Overflow } from 'components/partials/Overflow';
import { Illustration, TitleHighlight, Typography } from 'components/UI';
import { Breakpoints } from 'utils/enums';

const Wrapper = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(10),
  paddingBottom: theme.spacing(96),
  paddingTop: theme.spacing(7),
  position: 'relative',
  textAlign: 'center',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginBottom: theme.spacing(35),
    marginLeft: 'auto',
    marginRight: 'auto',
    maxHeight: theme.spacing(250),
    maxWidth: theme.spacing(450),
    minHeight: theme.spacing(260),
    paddingBottom: theme.spacing(300),
    paddingTop: theme.spacing(20),
  },

  [theme.breakpoints.up(Breakpoints.Lg)]: {
    paddingBottom: theme.spacing(300),
  },
}));

const Main = styled('div')({
  margin: 'auto',
  position: 'relative',
  zIndex: 9,
});

const Title = styled(TitleHighlight)(({ theme }) => ({
  display: 'inline-block',
  paddingBottom: theme.spacing(72),
  ...theme.typography.h3,
  fontWeight: 600,

  [theme.breakpoints.up(Breakpoints.Md)]: {
    ...theme.typography.h2,
    fontWeight: 600,
    paddingBottom: theme.spacing(13),
  },
}));

const Images = styled('div')(({ theme }) => ({
  '& .homepage-hero__0': {
    '& .asset': {
      left: 0,
      position: 'absolute',
      top: '-20%',
      width: '103%',
    },

    left: '-1em',
    position: 'absolute',
    top: theme.spacing(46),
    width: '60%',

    [theme.breakpoints.up(Breakpoints.Md)]: {
      left: '-5em',
      maxWidth: theme.spacing(90),
      top: '16%',
    },
    [theme.breakpoints.up(Breakpoints.Lg)]: {
      left: '1em',
    },
  },
  '& .homepage-hero__1': {
    '& .asset': {
      left: '22%',
      position: 'absolute',
      top: '10%',
      width: '62%',
    },

    display: 'none',

    [theme.breakpoints.up(Breakpoints.Md)]: {
      display: 'block',
      maxWidth: theme.spacing(90),
      position: 'absolute',
      right: '-4em',
      top: '10%',
    },
    [theme.breakpoints.up(Breakpoints.Lg)]: {
      right: '2em',
    },
  },
  '& .homepage-hero__2': {
    '& .asset': {
      left: '-53%',
      position: 'absolute',
      top: '2%',
      width: '198%',
    },

    bottom: theme.spacing(30),
    left: '7%',
    position: 'absolute',
    width: '40%',

    [theme.breakpoints.up(Breakpoints.Sm)]: {
      bottom: '4%',
      left: '-2%',
      maxWidth: theme.spacing(56),
    },

    [theme.breakpoints.up(Breakpoints.Md)]: {
      bottom: '18%',
      left: '0%',
      maxWidth: theme.spacing(56),
    },
    [theme.breakpoints.up(Breakpoints.Lg)]: {
      left: '5%',
    },
  },
  '& .homepage-hero__3': {
    '& .asset': {
      left: '-34%',
      position: 'absolute',
      top: '-14%',
      width: '170%',
    },

    bottom: 0,
    position: 'absolute',
    right: '-8%',
    width: '45%',

    [theme.breakpoints.up(Breakpoints.Md)]: {
      bottom: '10%',
      left: 'auto',
      maxWidth: theme.spacing(84),
      right: '35%',
      top: 'auto',
    },
  },
  '& .homepage-hero__4': {
    '& .asset': {
      left: '-60%',
      position: 'absolute',
      top: '4%',
      width: '150%',
    },

    left: 'auto',
    position: 'absolute',
    right: '-10%',
    top: theme.spacing(77),
    width: '41%',

    [theme.breakpoints.up(Breakpoints.Md)]: {
      bottom: '25%',
      display: 'block',
      maxWidth: theme.spacing(56),
      right: '-4em',
      top: 'auto',
    },
    [theme.breakpoints.up(Breakpoints.Lg)]: {
      right: '-1em',
    },
  },
  bottom: 0,
  left: 0,
  position: 'absolute',
  right: 0,
  top: 0,
  zIndex: 1,
}));

const DifferentiatorWrapper = styled('div')(({ theme }) => ({
  borderRadius: theme.borderRadius[2],
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: theme.spacing(10),
  overflow: 'hidden',
  textAlign: 'center',
  width: '95%',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    flexDirection: 'row',
    textAlign: 'center',
    width: '70%',
  },
}));

const Differentiator = styled('div')(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: theme.spacing(10),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(10),
  textAlign: 'center',
  width: '100%',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    width: '25%',
  },
}));

const DifferentiatorText = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(0, 10),
  [theme.breakpoints.up(Breakpoints.Md)]: {
    ...theme.typography.body2,
  },
}));

const DifferentiatorTitle = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(0, 0, 2),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    ...theme.typography.body1,
    fontWeight: 500,
  },
}));

const ImageWrap = styled('div')(({ theme }) => ({
  '& > *': {
    margin: 'auto 0',
  },
  display: 'flex',
  height: theme.typography.pxToRem(70),
  marginBottom: theme.spacing(4),
}));

const Image = styled(GatsbyImage)(({ theme }) => ({
  display: 'inline-block',
  marginBottom: theme.spacing(4.5),
  maxWidth: theme.typography.pxToRem(50),
  width: '100%',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginBottom: theme.spacing(2.5),
  },
}));

interface FeatureProps {
  image: IGatsbyImageData;
  title: string;
  text: string;
}

const Feature: React.FC<FeatureProps> = ({ image, title, text }) => {
  return (
    <Differentiator>
      <ImageWrap>
        <Image alt="" className="feature--image-wrap" image={image} />
      </ImageWrap>
      <DifferentiatorTitle variant="h6">{title}</DifferentiatorTitle>
      <DifferentiatorText variant="body2">{text}</DifferentiatorText>
    </Differentiator>
  );
};

const Hero: React.FC<HeroProps> = ({ content, children }) => {
  return (
    <Overflow>
      <Wrapper>
        <Main>
          <Title as="h1">{content.title}</Title>
          {children}
          <DifferentiatorWrapper>
            {content.differentiators.map((listItem, index) => {
              return (
                <Feature
                  key={`${listItem.title}-${index}`}
                  image={listItem.image.childImageSharp.gatsbyImageData}
                  title={listItem.title}
                  text={listItem.text}
                />
              );
            })}
          </DifferentiatorWrapper>
        </Main>

        <Images>
          {content.images.map((item, index) => (
            <Illustration
              alt=""
              className={`homepage-hero__${index}`}
              key={`homepage-hero__${index}`}
              image={item.main.childImageSharp.gatsbyImageData}
              asset={item.asset?.childImageSharp.original.src}
              assetAspectRatio={
                item.asset
                  ? item.asset?.childImageSharp.original.width /
                    item.asset?.childImageSharp.original.height
                  : undefined
              }
            />
          ))}
        </Images>
      </Wrapper>
    </Overflow>
  );
};

const query = graphql`
  fragment HomepageHero on HomepageYaml {
    hero {
      title
      text
      differentiators {
        title
        text
        image {
          childImageSharp {
            gatsbyImageData
            original {
              height
              width
              src
            }
          }
        }
      }
      images {
        main {
          childImageSharp {
            gatsbyImageData
          }
        }
        asset {
          childImageSharp {
            original {
              height
              width
              src
            }
          }
        }
      }
    }
  }
`;

export interface HeroProps {
  children?: React.ReactNode;
  content: {
    title: string;
    text: string;
    images: {
      main: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData;
          original: IImage;
        };
      };
      asset: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData;
          original: IImage;
        };
      };
    }[];
    differentiators: {
      image: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData;
          original: IImage;
        };
      };
      text: string;
      title: string;
    }[];
  };
}

export { Hero, query };
