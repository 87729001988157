/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { useQuery } from '@apollo/client';
import { styled, Typography } from '@material-ui/core';
import { graphql as gatsbyGraphql } from 'gatsby';
import gql from 'graphql-tag';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { letters, numbers } from './char';
import { SplitFlapField } from './SplitFlapField';
import { Container } from 'components/UI';

const Section = styled('section')(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.secondary.main}`,
  borderTop: `1px solid ${theme.palette.secondary.main}`,
  display: 'flex',
  flexWrap: 'wrap',
  paddingBottom: theme.spacing(5.25),
  width: '100%',
}));

const Desktop = styled(Section)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up(1440)]: {
    display: 'flex',
  },
}));

const Mobile = styled(Section)(({ theme }) => ({
  borderTop: 'none',
  display: 'block',

  [theme.breakpoints.up(1440)]: {
    display: 'none',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),

  [theme.breakpoints.up(1440)]: {
    marginBottom: theme.spacing(6),
    ...theme.typography.h4,
  },
}));

/**
 * Queries
 */
const PURCHASE_QUERY = gql`
  fragment airportDateTime on AirportDateTime {
    airport {
      iata
      name
      cityName
      countryCode
      countryName
      distance
      stateCode
    }
    dateTime {
      dateFormatted
      time24Hr
      time12Hr
    }
  }

  query GetFlightInfoDisplayData {
    bookingFlightInfoDisplayData {
      departure {
        ...airportDateTime
      }
      deposit
      price
      symbol
    }
  }
`;

const query = gatsbyGraphql`
  fragment HomepageLastPurchased on HomepageYaml {
    lastPurchased {
      sectionTitle
      categories {
        title
      }
    }
  }
`;

const CHANGE_INTERVAL = 6000; // 6 seconds

/**
 * Main entry component
 */
const PurchasedFlights: React.FC<PurchasedFlightsContentProps> = ({
  content,
}) => {
  const { data } = useQuery(PURCHASE_QUERY);

  const recentBookings = data?.bookingFlightInfoDisplayData as RecentBooking[];

  const [index, setIndex] = useState(0);

  const fields = useMemo<FieldData[]>(() => {
    if (!recentBookings?.length) return [];
    const currentBooking = recentBookings[index];
    return [
      // Date
      {
        charSet: numbers,
        flapsDesktop: 11,
        flapsMobile: 25,
        label: content.categories[0].title,
        showCurrency: false,
        value: currentBooking?.departure?.dateTime?.dateFormatted?.replace(
          /-/g,
          '/'
        ),
      },
      // Airport
      {
        charSet: letters,
        flapsDesktop: 7,
        flapsMobile: 25,
        label: content.categories[1].title,
        showCurrency: false,
        value: currentBooking?.departure?.airport?.iata?.toUpperCase(),
      },
      // Fare cost
      {
        charSet: numbers,
        flapsDesktop: 11,
        flapsMobile: 25,
        label: content.categories[2].title,
        showCurrency: true,
        value: currentBooking?.price?.toFixed(2).toString(),
      },
      // Upfront cost
      {
        charSet: numbers,
        flapsDesktop: 11,
        flapsMobile: 25,
        label: content.categories[3].title,
        showCurrency: true,
        value: currentBooking?.deposit?.toFixed(2).toString(),
      },
    ];
  }, [index, recentBookings, content.categories]);

  const nextBooking = useCallback(() => {
    if (!recentBookings?.length) return;
    setIndex((index) => (index + 1) % recentBookings.length);
  }, [recentBookings]);

  // Periodically change to the next booking
  useEffect(() => {
    nextBooking();
    const interval = setInterval(nextBooking, CHANGE_INTERVAL);

    return () => clearInterval(interval);
  }, [nextBooking]);

  if (
    !(
      data &&
      data.bookingFlightInfoDisplayData &&
      data.bookingFlightInfoDisplayData.length
    )
  ) {
    return null;
  }

  return (
    <Container>
      <Title variant="body1">{content.sectionTitle}</Title>
      <Desktop>
        {fields.map((field) => {
          return (
            <SplitFlapField
              key={`${field.label}-desktop`}
              value={field.value}
              numFlaps={field.flapsDesktop}
              label={field.label}
              charSet={field.charSet}
              showCurrency={field.showCurrency}
            />
          );
        })}
      </Desktop>
      <Mobile>
        {fields.map((field) => {
          return (
            <SplitFlapField
              key={`${field.label}-mobile`}
              value={field.value}
              numFlaps={field.flapsMobile}
              label={field.label}
              charSet={field.charSet}
              showCurrency={field.showCurrency}
            />
          );
        })}
      </Mobile>
    </Container>
  );
};

export interface ContentProps {
  sectionTitle: string;
  categories: CategoriesProps[];
}

export interface PurchasedFlightsContentProps {
  content: ContentProps;
}

interface CategoriesProps {
  title: string;
}

interface RecentBooking {
  departure: {
    airport: {
      iata: string;
      name: string;
      cityName: string;
      countryCode: string;
      countryName: string;
      distance: number;
      stateCode: string;
    };
    dateTime: {
      dateFormatted: string;
      time24Hr: string;
      time12Hr: string;
    };
  };
  deposit: number;
  price: number;
  symbol: string;
}

interface FieldData {
  charSet: string[];
  flapsDesktop: number;
  flapsMobile: number;
  showCurrency: boolean;
  label: string;
  value?: string;
}

export { PurchasedFlights, query };
