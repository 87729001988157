/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled } from '@material-ui/core';
import { graphql } from 'gatsby';
import { IGatsbyImageData, IImage } from 'gatsby-plugin-image';
import React from 'react';

import { Container, TitleHighlight, Typography } from 'components/UI';
import { Breakpoints } from 'utils/enums';

const Overflow = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  paddingBottom: '7em',
  [theme.breakpoints.up(Breakpoints.Md)]: {
    paddingBottom: 0,
  },
}));

const InnerContainer = styled('div')(({ theme }) => ({
  background: theme.palette.brandYellow500,
  borderRadius: theme.borderRadius[2],
  marginTop: theme.spacing(10),
  padding: theme.spacing(10, 4),
  width: '100%',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginTop: theme.spacing(45),
    overflow: 'hidden',
    padding: theme.spacing(21, 21, 0, 21),
  },
}));

const Header = styled('div')(({ theme }) => ({
  width: '100%',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const Title = styled(TitleHighlight)(({ theme }) => ({
  '& span': {
    fontSize: '1.8em',
  },
  ...theme.typography.h4,
  fontWeight: 500,
  margin: 'auto',
  textAlign: 'center',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    '& span': {
      display: 'block',
      fontSize: '2em',
    },

    margin: '0',
    textAlign: 'left',
    ...theme.typography.h3,
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(6, 0),
  padding: theme.spacing(0, 6),
  textAlign: 'center',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    textAlign: 'left',
    width: '34%',
    ...theme.typography.body1,
  },
}));

const ListImages = styled('div')(({ theme }) => ({
  [theme.breakpoints.up(Breakpoints.Md)]: {
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
}));

const ListItem = styled('div')(({ theme }) => ({
  '&:nth-child(1)': {
    '& .arrow': {
      bottom: 0,
      left: '25%',

      [theme.breakpoints.up(Breakpoints.Md)]: {
        bottom: 0,
        left: 'auto',
        right: '25%',
      },
    },
    '& .main-image': {
      position: 'relative',
      right: '-33%',
      [theme.breakpoints.up(Breakpoints.Md)]: {
        right: 0,
      },
    },
    marginBottom: '2em',
    marginTop: '4em',

    [theme.breakpoints.up(Breakpoints.Md)]: {
      marginBottom: '-10%',
      marginTop: 0,
      textAlign: 'left',
    },
  },
  '&:nth-child(2)': {
    '& .arrow': {
      bottom: 0,
      left: '35%',
      transform: 'scaleX(-1)',
      [theme.breakpoints.up(Breakpoints.Md)]: {
        bottom: 0,
        left: '35%',
        transform: 'none',
      },
    },
    '& .main-image': {
      left: '-33%',
      position: 'relative',
      [theme.breakpoints.up(Breakpoints.Md)]: {
        left: 0,
      },
    },
    marginBottom: '2em',

    [theme.breakpoints.up(Breakpoints.Md)]: {
      marginBottom: '-5%',
      textAlign: 'center',
    },
  },
  '&:nth-child(3)': {
    '& .arrow': {
      bottom: '-20%',
      left: '10%',
      transform: 'scaleX(-1) rotate(30deg)',
      [theme.breakpoints.up(Breakpoints.Md)]: {
        bottom: '30%',
        left: 'auto',
        right: '-10%',
        transform: 'none',
      },
    },
    '& .main-image': {
      position: 'relative',
      right: '-33%',
      [theme.breakpoints.up(Breakpoints.Md)]: {
        left: 0,
        right: 'auto',
      },
    },
    marginBottom: '-7em',
    [theme.breakpoints.up(Breakpoints.Md)]: {
      marginBottom: '-10%',
      textAlign: 'right',
    },
  },
  [theme.breakpoints.up(Breakpoints.Md)]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    position: 'relative',
    width: '28%',
  },
}));

const ItemImage = styled('img')({
  width: '100%',
});

const ItemArrow = styled('img')(({ theme }) => ({
  height: theme.spacing(13),
  position: 'absolute',
}));

const ItemCaption = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(32),
  marginBottom: theme.spacing(3),
  paddingBottom: theme.spacing(13),
  position: 'relative',
  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginBottom: theme.spacing(6),
  },
}));

const Design: React.FC<DesignProps> = ({ content }) => {
  return (
    <Overflow>
      <Container>
        <InnerContainer>
          <Header>
            <Title as="h2">{content.title}</Title>
            <Text variant="body2">{content.text}</Text>
          </Header>

          <ListImages>
            {content.images.map((item) => (
              <ListItem key={item.title}>
                <ItemCaption variant="subtitle2">
                  {item.title}
                  <ItemArrow
                    alt="illustration"
                    className="arrow"
                    src={item.arrow.childImageSharp.original.src}
                  />
                </ItemCaption>
                <ItemImage
                  className="main-image"
                  src={item.image.childImageSharp.original.src}
                  alt={item.title}
                />
              </ListItem>
            ))}
          </ListImages>
        </InnerContainer>
      </Container>
    </Overflow>
  );
};

export interface DesignProps {
  content: {
    title: string;
    text: string;
    images: {
      title: string;
      arrow: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData;
          original: IImage;
        };
      };
      image: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData;
          original: IImage;
        };
      };
    }[];
  };
}

const query = graphql`
  fragment HomepageDesign on HomepageYaml {
    design {
      title
      text
      images {
        title
        image {
          childImageSharp {
            gatsbyImageData
            original {
              width
              height
              src
            }
          }
        }
        arrow {
          childImageSharp {
            gatsbyImageData
            original {
              width
              height
              src
            }
          }
        }
      }
    }
  }
`;

export { Design, query };
