/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled, Theme, Typography } from '@material-ui/core';
import { graphql } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import React, { ReactNode } from 'react';

import { Feature } from 'components/partials/Feature';
import { Col, Container, Link, TitleHighlight } from 'components/UI';
import { Breakpoints } from 'utils/enums';

// Types
interface LinkProps {
  linkText: { children?: ReactNode };
  linkUrl: { children?: ReactNode };
  image: { childImageSharp: { gatsbyImageData: IGatsbyImageData } };
  title: string;
  text: string;
}

export interface ContactProps {
  content: {
    links: LinkProps[];
    title: string;
    text: string;
    team: { childImageSharp: { gatsbyImageData: IGatsbyImageData } }[];
  };
}

// Components

const Wrapper = styled('section')({
  width: '100%',
});

const InnerContainer = styled('div')(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: theme.borderRadius[2],
  display: 'flex',
  padding: theme.spacing(10, 0),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    padding: theme.spacing(21),
    paddingBottom: theme.spacing(12),
  },
}));

const HeadWrapper = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
  textAlign: 'center',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    textAlign: 'left',
  },
}));

const List = styled('div')(({ theme }) => ({
  [theme.breakpoints.up(Breakpoints.Md)]: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const Title = styled(TitleHighlight)(({ theme }) => ({
  '& span': {
    display: 'inline-block',
  },
  ...theme.typography.h4,
  fontWeight: 500,
  margin: 'auto',
  marginBottom: theme.spacing(10),
  textAlign: 'center',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    margin: '0',
    textAlign: 'left',
    ...theme.typography.h3,
  },
}));

const Item = styled(({ numberOfColumn: _, ...p }) => <div {...p} />)<
  Theme,
  { numberOfColumn: number }
>(({ theme, numberOfColumn }) => ({
  '& .feature--image-wrap': {
    margin: 'auto',
  },

  '&:not(:last-child)': {
    marginBottom: theme.spacing(12),
  },

  [theme.breakpoints.up(Breakpoints.Sm)]: {
    maxWidth: `50%`,
  },

  [theme.breakpoints.up(Breakpoints.Md)]: {
    margin: 'none',
    maxWidth: `calc((100% / ${numberOfColumn}) - 6em)`,
    textAlign: 'left',
  },

  textAlign: 'center',
}));

const LinkComponent = styled(Link)(({ theme }) => ({
  '& span': {
    fontWeight: 500,
  },
  display: 'block',
  marginTop: theme.spacing(4),
}));

// Link Instance
const linkInstance: React.FC = (url, text) => {
  return <LinkComponent to={url}>{text}</LinkComponent>;
};

const Text = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(0, 0, 4),
  padding: theme.spacing(0, 10),
  [theme.breakpoints.up(Breakpoints.Md)]: theme.typography.body1,
}));

const Contact: React.FC<ContactProps> = ({ content }) => {
  return (
    <Container>
      <InnerContainer>
        <Wrapper>
          <HeadWrapper>
            <Col xs={12} md={6}>
              <Title as="h2">{content.title}</Title>
            </Col>
            <Col xs={12} md={6}>
              <Text variant="body2">{content.text}</Text>
            </Col>
          </HeadWrapper>

          <List>
            {content.links.map((item) => {
              return (
                <Item numberOfColumn={content.links.length} key={item.title}>
                  <Feature
                    image={item.image.childImageSharp.gatsbyImageData}
                    title={item.title}
                    text={item.text}
                    linkInstance={linkInstance(item.linkUrl, item.linkText)}
                  />
                </Item>
              );
            })}
          </List>
        </Wrapper>
      </InnerContainer>
    </Container>
  );
};

const query = graphql`
  fragment HomepageContact on HomepageYaml {
    contact {
      title
      text
      team {
        childImageSharp {
          gatsbyImageData
        }
      }
      links {
        title
        text
        linkUrl
        linkText
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export { Contact, query };
