/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { graphql } from 'gatsby';
import React from 'react';

import { DownloadSection } from '../components/contents/Homepage/DownloadSection';
import { Benefits, BenefitsProps } from 'components/contents/Homepage/Benefits';
import { Contact, ContactProps } from 'components/contents/Homepage/Contact';
import { Design, DesignProps } from 'components/contents/Homepage/Design';
import { Hero, HeroProps } from 'components/contents/Homepage/Hero';
import {
  PurchasedFlights,
  PurchasedFlightsContentProps,
} from 'components/contents/Homepage/PurchasedFlights';
import { Reviews, ReviewsProps } from 'components/contents/Homepage/Reviews';
import { Calculator } from 'components/partials/Calculator';
import { DownloadProps } from 'components/partials/Download';
import Layout from 'components/partials/Layout';
import { LongList, LongListProps } from 'components/partials/LongList';
import { PressSlider } from 'components/partials/PressSlider';

// Types
interface Props {
  data: {
    downloadYaml: DownloadProps;
    homepageYaml: {
      metadata: MetaData;
      hero: HeroProps['content'];
      benefits: BenefitsProps['content'];
      design: DesignProps['content'];
      contact: ContactProps['content'];
      reviews: ReviewsProps;
      lastPurchased: PurchasedFlightsContentProps['content'];
      different: LongListProps['content'];
    };
  };
}

const Home: React.FC<Props> = ({ data }) => {
  const metadata = data.homepageYaml.metadata;
  return (
    <Layout title={metadata.title} description={metadata.description}>
      <Hero content={data.homepageYaml.hero}>
        <Calculator />
      </Hero>
      <DownloadSection content={data.downloadYaml} />
      <Benefits content={data.homepageYaml.benefits} />
      <Design content={data.homepageYaml.design} />
      <Reviews
        image={data.homepageYaml.reviews.image}
        title={data.homepageYaml.reviews.title}
        text={data.homepageYaml.reviews.text}
        linkText={data.homepageYaml.reviews.linkText}
        linkUrl={data.homepageYaml.reviews.linkUrl}
      />
      <PurchasedFlights content={data.homepageYaml.lastPurchased} />
      <LongList content={data.homepageYaml.different} />
      <Contact content={data.homepageYaml.contact} />
      <PressSlider />
    </Layout>
  );
};

export const query = graphql`
  query HomePageQuery {
    downloadYaml {
      ...DownloadContent
    }
    homepageYaml {
      metadata {
        title
        description
      }
      different {
        title
        list {
          title
          text
          image {
            childImageSharp {
              gatsbyImageData
              original {
                height
                width
                src
              }
            }
          }
        }
      }
      ...HomepageHero
      ...HomepageDesign
      ...HomepageBenefits
      ...HomepageStories
      ...HomepageLastPurchased
      ...HomepageContact
    }
  }
`;

export default Home;
