/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled, Theme, withTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData, IImage } from 'gatsby-plugin-image';
import React, { useEffect, useRef, useState } from 'react';

import { Overflow } from 'components/partials/Overflow';
import {
  Container,
  Illustration,
  IllustrationProps,
  TitleHighlight,
  Typography,
} from 'components/UI';
import { Breakpoints } from 'utils/enums';

/**
 * Parts
 */
const HeroTitle = styled(TitleHighlight)(({ theme }) => ({
  [theme.breakpoints.up(Breakpoints.Md)]: {
    '& > span': {
      fontSize: '2.6em',
    },

    ...theme.typography.h3,
    margin: '3em auto',
    maxWidth: theme.spacing(120),
  },

  ...theme.typography.h4,
  margin: `${theme.spacing(10)}px auto ${theme.spacing(20)}px`,
  maxWidth: theme.spacing(50),
  textAlign: 'center',
}));

const ItemDesktop = styled(({ active: _, ...p }) => <button {...p} />)<
  Theme,
  { active: boolean }
>(({ theme, active }) => ({
  '&:before': {
    background: theme.palette.primary.dark,
    borderRadius: theme.borderRadius[1],
    content: '""',
    filter: 'blur(10px)',
    height: '85%',
    left: '5%',
    opacity: active ? 0.1 : 0,
    position: 'absolute',
    top: active ? '15%' : '0%',
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.easeInOut,
    }),
    width: '90%',
    zIndex: -1,
  },

  backgroundColor: active ? theme.palette.common.white : 'transparent',
  border: 0,
  borderRadius: theme.borderRadius[1],
  cursor: 'pointer',
  display: 'flex',
  marginBottom: theme.spacing(6.5),
  outline: 0,
  padding: theme.spacing(4),
  position: 'relative',
  textAlign: 'left',
  transition: theme.transitions.create('background', {
    easing: theme.transitions.easing.easeInOut,
  }),
  width: '100%',
}));

const ItemMobile = styled(({ active: _, ...p }) => <button {...p} />)<
  Theme,
  { active: boolean }
>(({ theme, active }) => ({
  '&:before': {
    background: theme.palette.primary.dark,
    borderRadius: theme.borderRadius[1],
    content: '""',
    filter: 'blur(10px)',
    height: '85%',
    left: '5%',
    opacity: active ? 0.1 : 0,
    position: 'absolute',
    top: '15%',
    width: '90%',
    zIndex: -1,
  },

  backgroundColor: theme.palette.common.white,
  border: 0,
  borderRadius: theme.borderRadius[1],
  cursor: 'pointer',
  marginBottom: theme.spacing(6.5),
  marginRight: theme.spacing(2),
  minWidth: theme.spacing(60),
  opacity: active ? 1 : 0.7,
  outline: 0,
  padding: theme.spacing(4),
  position: 'relative',
  scrollSnapAlign: 'center',
  textAlign: 'center',
  transition: theme.transitions.create('background', {
    easing: theme.transitions.easing.easeInOut,
  }),
  width: '100%',
}));

const ItemTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  marginBottom: theme.spacing(1),
}));

const ItemIcon = styled(GatsbyImage)(({ theme }) => ({
  width: theme.spacing(12),
}));

const ItemContent = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(4.5),
  paddingRight: theme.spacing(14),
}));

const Slider = styled('div')(({ theme }) => ({
  width: '100%',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const SidebarWrapperDesktop = styled('div')(({ theme }) => ({
  '& > div': {
    margin: 'auto',
    width: '100%',
  },
  display: 'none',
  width: '27%',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    display: 'flex',
  },
}));

const SidebarWrapperMobile = styled('div')(({ theme }) => ({
  '& > div': {
    '&:first-child': {
      paddingLeft: '30%',
    },
    '&:last-child': {
      paddingRight: '30%',
    },
    padding: '0 .5em',
  },
  '&::-webkit-scrollbar': {
    display: 'none',
  },

  '-webkit-overflow-scrolling': 'touch',
  display: 'flex',
  marginLeft: theme.spacing(-5),
  marginRight: theme.spacing(-5),
  marginTop: theme.spacing(10),
  overflowX: 'auto',
  scrollBehavior: 'smooth',
  scrollSnapType: 'x mandatory',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    display: 'none',
  },
}));

const MainWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    display: 'flex',
    width: '60%',
  },
}));

const MainImage = styled(Illustration)<
  Theme,
  { active: boolean } & IllustrationProps
>(({ theme, active }) => ({
  '&:not(:first-child)': {
    bottom: 0,
    left: 0,
    position: 'absolute !important' as any,
    right: 0,
    top: 0,
  },
  '&:nth-child(1) .asset': {
    right: '-19%',
    top: '-15%',
    width: '117%',
  },
  '&:nth-child(2) .asset': {
    right: 0,
    top: '-2%',
    width: '55%',
  },
  '&:nth-child(3) .asset': {
    right: 0,
    top: '-10%',
    width: '67%',
  },
  opacity: active ? 1 : 0,
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.easeInOut,
  }),
  width: '100%',
}));

/**
 * Constants
 */
const TIMEOUT_NEXT_ITEM = 5000;

/**
 * Main entry component
 */
const BenefitsWrap: React.FC<BenefitsProps & { theme: Theme }> = ({
  content,
  theme,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const horizontalScrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (horizontalScrollRef.current) {
      const handle = () => {
        const scrollLength = horizontalScrollRef?.current?.scrollLeft ?? 0;
        const windowWidth = window.innerWidth;
        const scrollView = scrollLength * content.slider.length;

        const newIndex = Math.round(scrollView / (windowWidth + scrollLength));

        setActiveIndex(newIndex);
      };

      horizontalScrollRef.current.addEventListener('scroll', handle);

      return () => {
        horizontalScrollRef?.current?.removeEventListener('scroll', handle);
      };
    }

    return () => undefined;
  }, [content.slider.length]);

  useEffect(() => {
    if (matches) {
      const timer = setTimeout(() => {
        setActiveIndex((prev) => (prev + 1) % content.slider.length);
      }, TIMEOUT_NEXT_ITEM);

      return () => {
        clearTimeout(timer);
      };
    }

    return () => undefined;
  }, [activeIndex, content.slider.length, matches]);

  return (
    <Overflow>
      <Container>
        <HeroTitle as="h2">{content.title}</HeroTitle>
      </Container>

      <Container>
        <Slider>
          <SidebarWrapperDesktop>
            <div>
              {content.slider.map((item, index) => {
                return (
                  <ItemDesktop
                    key={item.title}
                    active={activeIndex === index}
                    onClick={() => setActiveIndex(index)}
                  >
                    <ItemIcon
                      image={item.icon.childImageSharp.gatsbyImageData}
                      alt={item.title}
                    />
                    <ItemContent>
                      <ItemTitle>{item.title}</ItemTitle>
                      <Typography color="textSecondary" variant="body2">
                        {item.tagline}
                      </Typography>
                    </ItemContent>
                  </ItemDesktop>
                );
              })}
            </div>
          </SidebarWrapperDesktop>

          <MainWrapper>
            {content.slider.map((item, index) => {
              return (
                <MainImage
                  alt=""
                  active={activeIndex === index}
                  key={item.title}
                  image={item.image.childImageSharp.gatsbyImageData}
                  asset={item.asset?.childImageSharp.original.src}
                  assetAspectRatio={
                    item.asset
                      ? item.asset.childImageSharp.original.width /
                        item.asset.childImageSharp.original.height
                      : undefined
                  }
                />
              );
            })}
          </MainWrapper>

          <SidebarWrapperMobile ref={horizontalScrollRef}>
            {content.slider.map((item, index) => {
              return (
                <div key={item.title}>
                  <ItemMobile
                    active={activeIndex === index}
                    onClick={() => setActiveIndex(index)}
                  >
                    <ItemTitle>{item.title}</ItemTitle>
                    <Typography color="textSecondary" variant="body2">
                      {item.tagline}
                    </Typography>
                  </ItemMobile>
                </div>
              );
            })}
          </SidebarWrapperMobile>
        </Slider>
      </Container>
    </Overflow>
  );
};

export interface BenefitsProps {
  content: {
    title: string;
    slider: {
      title: string;
      tagline: string;
      icon: { childImageSharp: { gatsbyImageData: IGatsbyImageData } };
      image: { childImageSharp: { gatsbyImageData: IGatsbyImageData } };
      asset: { childImageSharp: { original: IImage } };
    }[];
  };
}

const query = graphql`
  fragment HomepageBenefits on HomepageYaml {
    benefits {
      title
      slider {
        title
        tagline
        icon {
          childImageSharp {
            gatsbyImageData
          }
        }
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        asset {
          childImageSharp {
            original {
              height
              width
              src
            }
          }
        }
      }
    }
  }
`;

const Benefits = withTheme(BenefitsWrap);

export { Benefits, query };
