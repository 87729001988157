/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { useLazyQuery, useQuery } from '@apollo/client';
import { styled } from '@material-ui/core';
import * as Sentry from '@sentry/gatsby';
import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { Result } from './Result';
import {
  Button,
  Form,
  FormDate,
  FormInput,
  Typography,
  Wrapper,
} from 'components/UI';
import { Breakpoints } from 'utils/enums';
import { useDebounce } from 'utils/useDebounce';

const CalculatorWrapper = styled(Wrapper)(({ theme }) => ({
  '&:after': {
    background: theme.palette.common.black,
    content: '""',
    display: 'block',
    filter: 'blur(15px)',
    height: '90%',
    left: '5%',
    opacity: 0.05,
    position: 'absolute',
    top: '8%',
    width: '90%',
    zIndex: -1,
  },

  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(0, 2),
  maxWidth: theme.spacing(205),
  position: 'relative',
  textAlign: 'left',
  zIndex: 2,

  [theme.breakpoints.up(Breakpoints.Md)]: {
    flexDirection: 'row',
    margin: 'auto',
  },
}));

const CalculatorCommon = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.borderRadius[2],
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(5),
  width: '100%',
}));

const CalculatorParam = styled(CalculatorCommon)(({ theme }) => ({
  borderTopLeftRadius: theme.borderRadius[2],
  borderTopRightRadius: theme.borderRadius[2],
  marginRight: 0,
  position: 'relative',
  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginRight: theme.spacing(0.5),
  },
}));

const ResultWrapper = styled(CalculatorCommon)(({ theme }) => ({
  '&:after': {
    background: `repeating-linear-gradient(90deg, ${
      theme.palette.common.white
    }, ${theme.palette.common.white} ${theme.typography.pxToRem(
      10
    )}, transparent ${theme.typography.pxToRem(
      10
    )}, transparent ${theme.typography.pxToRem(16)} )`,
    content: '""',
    height: theme.typography.pxToRem(5),
    left: theme.borderRadius[2],
    position: 'absolute',
    right: theme.borderRadius[2],
    top: theme.typography.pxToRem(-2),
    zIndex: -1,
  },

  borderBottomLeftRadius: theme.borderRadius[2],
  borderBottomRightRadius: theme.borderRadius[2],
  display: 'flex',
  flexDirection: 'column',
  marginTop: 2,
  maxWidth: '100%',
  position: 'relative',
  textAlign: 'center',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    '&:after': {
      background: `repeating-linear-gradient( to bottom, ${
        theme.palette.common.white
      }, ${theme.palette.common.white} ${theme.typography.pxToRem(
        10
      )}, transparent ${theme.typography.pxToRem(
        10
      )}, transparent ${theme.typography.pxToRem(16)} )`,
      content: '""',
      height: '90%',
      left: theme.typography.pxToRem(-2),
      position: 'absolute',
      top: theme.typography.pxToRem(10),
      width: theme.typography.pxToRem(5),
      zIndex: -1,
    },
    marginTop: 0,
    maxWidth: theme.typography.pxToRem(330),
  },
}));

const SectionLabelForm = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(0.5),
}));

const SectionLabelSubtitleForm = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const SectionLabelResult = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  marginBottom: theme.spacing(2),
}));

const CalendarInfo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.brandYellow500,
  borderRadius: theme.shape.borderRadius,
  display: 'inline-block',
  fontSize: theme.typography.pxToRem(14),
  marginBottom: theme.spacing(3),
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  padding: theme.spacing(2),
  textAlign: 'center',
}));

// Gatsby API Query
const query = graphql`
  query calculatorQuery {
    calculatorYaml {
      calculator {
        parameters {
          label
          subtitle
          forms {
            costLabel
            costPlaceholder
            dateLabel
            noMinimumCostPlaceholder
          }
          errorMsg {
            noConstraints
          }
        }
        results {
          buttonText
          label
          subLabel
          buttonUrl
        }
      }
    }
  }
`;

// Terminal API Query
const PLAN_QUERY = gql`
  query GetPaymentsPlans($input: PaymentPlansInput!) {
    paymentPlans(input: $input) {
      cost
      departureDate
      plans {
        intervalLabel
        intervalInDays
        payments {
          status
          symbol
          amount
          deposit
          due
          currency
        }
      }
    }
  }
`;

// Terminal API Query
const BOOKING_CONSTRAINTS = gql`
  query BookingConstraints {
    bookingConstraints {
      maxCompletePurchaseTime
      minDepartureDate
      minDepartureDateWithInstallments
      minFareCost
    }
  }
`;

const Calculator: React.FC = () => {
  /**
   * UI States
   */
  const [cost, setCost] = React.useState('');
  const debounceCost = useDebounce(cost);
  const [departureDate, setDepartureDate] = useState<moment.Moment>();

  /**
   * State handles
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCost(event.target.value);
  };
  const dateOnChange = (e: {
    target: { value: React.SetStateAction<moment.Moment | undefined> };
  }) => setDepartureDate(e.target.value);

  /**
   * Queries
   */
  const {
    data: constraints,
    error: constraintsError,
    loading: constraintsLoading,
  } = useQuery<ConstraintsProps>(BOOKING_CONSTRAINTS);
  const [fetchCalculator, { loading, error, data }] = useLazyQuery(PLAN_QUERY);

  // Sentry debug logs
  if (constraints) {
    Sentry.addBreadcrumb({ message: 'Loaded constraints' });
  }

  if (constraintsError) {
    Sentry.captureException(constraintsError);
  }

  if (data) {
    Sentry.addBreadcrumb({ message: 'Got paymentPlans data' });
  }

  if (error) {
    Sentry.captureException(error);
  }

  /**
   * Constraints
   */
  const MIN_COST = constraints?.bookingConstraints?.minFareCost;
  const MIN_DATE =
    constraints?.bookingConstraints?.minDepartureDateWithInstallments;

  // Convert to UTC
  const MIN_DATE_MOMENT = MIN_DATE && moment.utc(MIN_DATE);

  const missingConstraints = !MIN_COST || !MIN_DATE;

  if (missingConstraints && !constraintsLoading) {
    Sentry.captureException('Missing booking constraints');
  }

  if (missingConstraints && debounceCost) {
    Sentry.captureException('Failed to calculate due to missing constraints', {
      extra: { cost: debounceCost },
    });
  }

  /**
   * Statics
   */
  const formatGraphqlError =
    error?.graphQLErrors
      .map((e) => e?.extensions?.exception.code)
      .filter(Boolean) ?? [];

  const strippedCost = Number(debounceCost?.replace(/[,$]/g, ''));

  const costTooLow =
    (!!MIN_COST && !!strippedCost && strippedCost < MIN_COST) ||
    formatGraphqlError.find((e) => e === 'ERR_FEE_MINIMUM');

  const dateTooSoon =
    (!!MIN_DATE && !!departureDate?.isBefore(MIN_DATE_MOMENT)) ||
    formatGraphqlError.find((e) => e === 'ERR_PAYMENTPLAN_DEPARTS_TOO_SOON');

  const hasValidResult =
    !error && cost && !costTooLow && departureDate && !dateTooSoon;

  const content = useStaticQuery<{ calculatorYaml: CalculatorProps }>(query);
  const calculator = content.calculatorYaml.calculator;
  const costPlaceholder = MIN_COST
    ? calculator.parameters.forms.costPlaceholder.replace(
        '{{cost}}',
        `${MIN_COST}`
      )
    : calculator.parameters.forms.noMinimumCostPlaceholder;

  useEffect(() => {
    if (strippedCost && !costTooLow && departureDate && !dateTooSoon) {
      Sentry.addBreadcrumb({ message: 'Firing paymentPlans query' });
      fetchCalculator({
        variables: { input: { cost: strippedCost, departureDate } },
      });
    }
  }, [costTooLow, dateTooSoon, departureDate, fetchCalculator, strippedCost]);

  return (
    <CalculatorWrapper>
      <CalculatorParam>
        <SectionLabelForm variant="h4">
          {calculator.parameters.label}
        </SectionLabelForm>

        <SectionLabelSubtitleForm variant="body2" color="textSecondary">
          {calculator.parameters.subtitle}
        </SectionLabelSubtitleForm>

        <Form>
          <FormInput
            mask={createNumberMask({ allowDecimal: true })}
            name="price"
            label={calculator.parameters.forms.costLabel}
            placeholder={costPlaceholder}
            value={cost}
            onChange={handleChange}
            errorMessage={
              missingConstraints && debounceCost
                ? calculator.parameters.errorMsg.noConstraints
                : costTooLow
                ? `$${MIN_COST} Minimum`
                : undefined
            }
          />

          <FormDate
            initialVisibleMonth={() => moment(MIN_DATE_MOMENT) as any}
            isDayBlocked={(data: moment.Moment) =>
              data.endOf('day').isBefore(MIN_DATE_MOMENT)
            }
            date={departureDate}
            onChange={dateOnChange}
            placeholder={calculator.parameters.forms.dateLabel}
            errorMessage={
              dateTooSoon ? `The departure date is too soon` : undefined
            }
            renderCalendarInfo={() => (
              <CalendarInfo>
                To use our installment plans, your booking must depart after{' '}
                {moment.utc(MIN_DATE).format('MMMM Do')}.
              </CalendarInfo>
            )}
          />
        </Form>
      </CalculatorParam>

      <ResultWrapper>
        <SectionLabelResult variant="body2">
          {calculator.results.label}
        </SectionLabelResult>

        <Result data={data} loading={loading} error={!hasValidResult} />

        <SectionLabelResult variant="body2" color="textSecondary">
          {calculator.results.subLabel}
        </SectionLabelResult>

        <Button to={calculator.results.buttonUrl}>
          {calculator.results.buttonText}
        </Button>
      </ResultWrapper>
    </CalculatorWrapper>
  );
};

type ConstraintsProps = {
  bookingConstraints?: Record<
    | 'maxCompletePurchaseTime'
    | 'minDepartureDate'
    | 'minDepartureDateWithInstallments'
    | 'minDepartureDays'
    | 'minFareCost',
    number
  >;
};

interface CalculatorProps {
  calculator: {
    parameters: {
      forms: {
        costLabel: string;
        costPlaceholder: string;
        dateLabel: string;
        noMinimumCostPlaceholder: string;
      };
      label: string;
      subtitle: string;
      errorMsg: {
        noConstraints: string;
      };
    };
    results: {
      buttonText: string;
      label: string;
      subLabel: string;
      buttonUrl: string;
    };
  };
}

export { Calculator };
