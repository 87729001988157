/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import './animation.css';

import { styled } from '@material-ui/core';
import React from 'react';

const Loading = styled('div')(({ theme }) => ({
  '@media (prefers-reduced-motion)': {
    animation: 'none',
  },
  animation: 'progress 2s linear infinite',
  backgroundColor: theme.palette.secondary.main,
  borderRadius: theme.borderRadius[4],
}));

const Wrapper = styled('div')({
  display: 'flex',
});

const Title = styled(Loading)(({ theme }) => ({
  color: 'transparent',
  display: 'inline-block',
  height: '100%',
  ...theme.typography.h2,
}));
const Small = styled(Loading)(({ theme }) => ({
  color: 'transparent',
  display: 'inline-block',
  height: '100%',
  marginLeft: theme.spacing(2),
  ...theme.typography.caption,
}));

const Skeleton: React.FC = () => {
  return (
    <Wrapper>
      <Title>Loading</Title>
      <Small>Loading</Small>
    </Wrapper>
  );
};

export { Skeleton };
