/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled, Theme, Typography } from '@material-ui/core';
import React from 'react';

import { Skeleton } from '.';
import { Breakpoints } from 'utils/enums';

// Types
interface ResultProps {
  data: {
    paymentPlans: {
      plans: {
        intervalInDays: number;
        payments: {
          symbol: string;
          amount: number;
          deposit: boolean;
        }[];
      }[];
    };
  };
  error: boolean;
  loading: boolean;
}

// Components
const Wrapper = styled('div')<Theme, { loading?: boolean }>(
  ({ theme, loading }) => ({
    '& > *': {
      margin: 'auto 0',
    },
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    opacity: loading ? 0.3 : 1,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.easeInOut,
    }),
  })
);

const Title = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(3, 0),
  [theme.breakpoints.up(Breakpoints.Md)]: theme.typography.h1,
}));

const Caption = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  marginLeft: theme.spacing(2),
  marginTop: theme.spacing(1.5),
  textTransform: 'uppercase',
}));

const Flex = styled(Typography)({
  display: 'flex',
});

const Result: React.FC<ResultProps> = ({ data, error, loading }) => {
  const planWithInstallments = data?.paymentPlans?.plans?.find(
    (p) => p.intervalInDays > 0
  );
  const symbol = planWithInstallments?.payments[0].symbol;
  const value = planWithInstallments?.payments
    .find((e) => e.deposit)
    ?.amount.toFixed(0);

  if (data) {
    return (
      <Wrapper loading={loading || error}>
        <Flex>
          <Title className="main-value" variant="h2">
            {symbol}
            {value}
          </Title>
          <Caption variant="caption">(Now)</Caption>
        </Flex>
      </Wrapper>
    );
  }

  if (loading) {
    return (
      <Wrapper>
        <Skeleton />
      </Wrapper>
    );
  }

  return (
    <Wrapper loading={loading || error}>
      <Title className="main-value" variant="h2">
        $0
      </Title>
    </Wrapper>
  );
};

export { Result };
